export default function Footer(footerData) {
    let options = footerData.footer.data.siteOptions.siteOptions;
    let menuItems = footerData.footer?.data?.menu.menuItems.edges;

    return (
    <footer>
        <div id="top-footer-container" className="container-fluid">
            <div className="container">
                <div>
                    <div>
                        <a href="/">
                            <img width={144} height={52} src="/assets/images/footer-logo.svg" alt="Natural Lands" />
                        </a>
                        <div id="address" dangerouslySetInnerHTML={{__html: options.address}}></div>
                        <div id="mobile-address" dangerouslySetInnerHTML={{__html: options.mobileAddress}}></div>
                        <div id="footer-social-container">
                            {
                                options.facebookLink && (
                                    <a href={`${options.facebookLink}`} target="_blank">
                                        <span className="icon-facebook"></span>
                                    </a>
                                )
                            }
                            {
                                options.facebookLink && (
                                    <a href={`${options.instagramLink}`} target="_blank">
                                        <span className="icon-instagram"></span>
                                    </a>
                                )
                            }
                            {
                                options.facebookLink && (
                                    <a href={`${options.linkedinLink}`} target="_blank">
                                        <span className="icon-linkedin"></span>
                                    </a>
                                )
                            }
                            {
                                options.facebookLink && (
                                    <a href={`${options.tiktokLink}`} target="_blank">
                                        <span className="icon-tiktok"></span>
                                    </a>
                                )
                            }
                        </div>
                        <div id="footer-disclaimer" dangerouslySetInnerHTML={{__html: options.disclaimer}}></div>
                        <div id="footer-logos">
                            {
                                options.logos && options.logos.length > 0 && options.logos.map((item, index) => (
                                    <div key={index}>{
                                        <img width={80} height={80} src={`${item.logo.sourceUrl}`} />
                                    }</div>
                                ))
                            }
                        </div>
                    </div>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: options.form}}></div>
                    </div>
                </div>
            </div>
        </div>
        <div id="bottom-footer-container" className="container-fluid">
            <div className="container xlarge-container">
                <div>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: options.copyrightContent}}></div>
                    </div>
                    <div>
                        <ul>
                            {
                                menuItems && menuItems.length > 0 && menuItems.map((item, index) => (
                                    <li key={index}>{
                                        <a href={`${item.node.path}`}>
                                            <span className="icon-arrow"></span>
                                            {item.node.label}
                                        </a>
                                    }</li>
                                ))
                            }
                        </ul>
                    </div>
                    <div>
                        <a aria-label="Handcrafted by (opens link in a new tab)" href="https://cacpro.com" target="_blank">
                            Handcrafted by 
                            <span className="icon-cacpro"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
}